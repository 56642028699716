<template>
  <div class="content">
    <p class="title">{{ details.title }}</p>
    <p class="date">{{ operateTime }}</p>
    <div class="box">
      <video-player v-if="details.video" class="video-player vjs-custom-skin vjs-big-play-centered box_video"
                    ref="videoPlayer" :playsinline="true" @play="onPlayerPlay($event)"
                    :options="playerOptions"/>
      <quillEdit class="box_html" :content="details.content"/>
    </div>
  </div>
</template>

<script>
import {videoPlayer} from "vue-video-player";
import 'video.js/dist/video-js.css';
import utils from "@/utils/utils";
import quillEdit from "./quill";

export default {
  props: {
    details: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {videoPlayer, quillEdit},
  watch: {
    details() {
      this.playerOptions.sources[0].src = utils.setOssUrl(this.details.video);
      this.operateTime = utils.getActivityDate(this.details.operateTime, "-");
      // 判断是否是阿里云的视频，阿里云的截取封面，非阿里云自动获取。后续去掉
      if (this.details.video && this.details.video.includes("fileserver/save/file")) {
        this.playerOptions.poster = utils.setOssUrl(this.details.video) + "?x-oss-process=video/snapshot,t_100,f_jpg,w_0,h_0,m_fast";
      }
    }
  },
  data() {
    return {
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          src: this.details.video, // 路径
          type: 'video/mp4' // 类型
        }],
        poster: "", // 你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true,// 全屏按钮
          volumePanel: {inline: false}
        }
      },
      detailsData: {},
      operateTime: ""
    };
  },
  mounted() {
  },
  methods: {
    // 播放回调
    onPlayerPlay() {
      this.$sensors.officialSite_WatchVideo({
        officialSite_e_title: document.title
      });
    }
  }
};
</script>
<style scoped lang="scss">
.content {
  padding: 40px 0 80px;
  border-bottom: 1px solid #EEEEEE;
  .title {
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    line-height: 48px;
    font-weight: bold;
  }
  .date {
    font-size: 26px;
    color: #666666;
    line-height: 32px;
    margin-top: 24px;
  }
  .box {
    margin-top: 40px;
    font-size: 28px;
    .box_video {
      width: 100%;
      height: 353px;
    }
    /deep/ .vjs-big-play-button {
      line-height: 1;
      border: none;
      width: 68px;
      height: 68px;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      margin-top: -34px;
      margin-left: -34px;
    }
    /deep/ .video-js .vjs-big-play-button .vjs-icon-placeholder {
      font-family: "font_family" !important;
      font-style: normal;
      color: #FFFFFF;
      font-size: 68px;
      &:before {
        font-family: "font_family" !important;
        font-style: normal;
        color: #FFFFFF;
        font-size: 68px;
        content: "\e61a";
      }
    }
    .box_html {
      margin-top: 40px;
      word-wrap: break-word;
      /deep/ img {
        max-width: 100%;
      }
      /deep/ video {
        max-width: 100%;
      }
    }
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .content {
    padding: 0 0 60px 0;
    border-bottom: 1px solid #EEEEEE;
    .title {
      font-size: 32px;
      font-weight: bold;
      color: #333333;
      line-height: 42px;
      font-weight: bold;
    }
    .date {
      font-size: 24px;
      color: #666666;
      line-height: 29px;
      margin-top: 24px;
    }
    .box {
      margin-top: 40px;
      font-size: 24px;
      .box_video {
        width: 100%;
        height: 473px;
      }
      /deep/ .vjs-big-play-button {
        line-height: 1;
        border: none;
        width: 88px;
        height: 88px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        margin-top: -44px;
        margin-left: -44px;
      }
      /deep/ .video-js .vjs-big-play-button .vjs-icon-placeholder {
        font-family: "font_family" !important;
        font-style: normal;
        color: #FFFFFF;
        font-size: 88px;
        &:before {
          font-family: "font_family" !important;
          font-style: normal;
          color: #FFFFFF;
          font-size: 88px;
          content: "\e61a";
        }
      }
      .box_html {
        margin-top: 40px;
        word-wrap: break-word;
        /deep/ img {
          max-width: 100%;
        }
        /deep/ video {
          max-width: 100%;
        }
      }
    }
  }
}
@media (min-width: 961px) {
  .content {
    width: 840px;
    padding: 0 40px 0 0;
    border-bottom: none;
    border-right: 1px solid #EEEEEE;
    .title {
      font-size: 30px;
      font-weight: bold;
      color: #333333;
      line-height: 40px;
    }
    .date {
      font-size: 16px;
      color: #666666;
      line-height: 18px;
      margin-top: 24px;
    }
    .box {
      margin-top: 40px;
      font-size: 16px;
      .box_html {
        font-size: 16px;
      }
      .box_video {
        width: 100%;
        height: 450px;
      }
      /deep/ .vjs-big-play-button {
        line-height: 1;
        border: none;
        width: 68px;
        height: 68px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        margin-top: -34px;
        margin-left: -34px;
      }
      /deep/ .video-js .vjs-big-play-button .vjs-icon-placeholder {
        font-family: "font_family" !important;
        font-style: normal;
        color: #FFFFFF;
        font-size: 68px;
        &:before {
          font-family: "font_family" !important;
          font-style: normal;
          color: #FFFFFF;
          font-size: 68px;
          content: "\e61a";
        }
      }
    }
  }
}
</style>

