<template>
  <div class="header js_header">
    <div class="header_box">
      <img class="logo" :src="utils.setOssUrl(basicInfo.logo)" alt="">
      <ul class="nav" v-show="mobileMore">
        <li
          :class="[
            ($route.name || '').includes('productIndex') ? 'active' : ''
          ]"
          @click="handleMenuClick('首页')"
        >
          <router-link to="/?source=header">首 页</router-link>
        </li>
        <li class="user_box js_user_box">
          <div class="user_service" @click="serviceClick">
            <span>用户服务</span>
            <i class="font_family" :class="[mobileService?'icon-common_arrow_up':'icon-common_arrow_down']"></i>
          </div>
          <div class="service" v-show="mobileService" @click="handleMenuClick('常见问题')">
            <router-link to="/userService/question" v-if="basicInfo.openModule.includes(5)">常见问题</router-link>
            <a @click="manualClick">操作手册</a>
          </div>
        </li>
        <li
          v-for="item in menuOption"
          :key="item.moduleType"
          class="sort_box"
          :class="[item.path.includes($route.name) ? 'active' : '']"
          @click="handleMenuClick(item.moduleName)"
        >
          <template>
            <router-link :to="item.path">{{ item.moduleName }}</router-link>
          </template>
        </li>
      </ul>
      <i class="font_family icon_more" :class="[mobileMore?'icon-nav_close':'icon-nav_menu']" @click="moreClick"></i>
    </div>
    <p class="mask" v-show="mobileMore" @click="closeMore"></p>
  </div>
</template>

<script>
import $ from 'jquery';
import {getProductManual} from "@/api/common/index.js";
import utils from "@/utils/utils";
import module from '@/const/module'
import modulePath from '@/utils/modulePath'

export default {
  name: "layoutHeader",
  data() {
    return {
      mobileMore: false,
      mobileService: false,
      link: "",
      utils
    };
  },
  created() {},
  mounted() {
    // this.getProductManualFun();
    this.onResize();
    this.setAnimation();
  },
  watch: {
    basicInfo() {
      let params = {
        productId: this.basicInfo.productId
      };
      getProductManual(params).then((res) => {
        if (res.success) {
          this.link = res.data ? res.data.link : "";
        }
      });
    }
  },
  computed: {
    basicInfo() {
      return this.$store.getters.getProductBasicInfo;
    },
    // 菜单配置
    menuOption() {
      const _module = Object.entries(module)
        .map((arr) => {
          const path = modulePath[arr[0]] ? modulePath[arr[0]].path : ''
          return {
            ...(arr[1] || {}),
            moduleKey: arr[0] || '',
            path
          }
        })
        .filter(({ isMenu, path }) => isMenu || path)
      const data = (this.basicInfo.openModule || [])
        .map((key) => {
          const res = _module.find(({ moduleType }) =>
            Object.is(moduleType, key)
          )
          return res
        })
        .filter((v) => v)
      return data
    }
  },
  methods: {
    /**
     * 菜单点击事件
     */
    handleMenuClick(name) {
      const params = {
        officialSite_e_name: name,
        officialSite_e_source: '菜单',
      };
      this.$sensors.officialSite_Router(params);
    },
    manualClick() {
      if (this.link) {
        window.open(this.link);
      } else {
        if (this.$route.path !== "/userService/manualNot") {
          this.$router.push({
            path: '/userService/manualNot'
          });
        }
      }
      this.handleMenuClick('操作手册')
    },
    setAnimation() {
      // 效果处理
      $(document.body).on('click', '.js_header .nav a', () => {
        this.mobileMore = false;
        this.mobileService = false;
        $(".js_header .mask,.js_header .service").hide();
        this.bodyFixed();
      });
      $(".js_header .nav li").mouseenter(() => {
        $(".js_header .mask").show();
      });
      $(".js_header .nav").mouseleave(() => {
        $(".js_header .mask").hide();
      });
      $(".js_header .user_box").mouseenter(() => {
        $(".js_header .service").show();
      });
      $(".js_header .user_box").mouseleave(() => {
        $(".js_header .service").hide();
      });
      $(".js_header .js_user_box").mouseenter(() => {
        $(".js_header .js_user_box .user_service i").addClass("icon-common_arrow_up");
      });
      $(".js_header .user_box").mouseleave(() => {
        $(".js_header .js_user_box .user_service i").removeClass("icon-common_arrow_up");
      });
    },
    onResize() {
      window.onresize = () => {
        let w = $(window).width();
        if (w > 960) {
          this.mobileMore = false;
          this.mobileService = false;
          this.bodyFixed();
        }
      };
    },
    serviceClick() {
      let w = $(window).width();
      if (w < 960) {
        this.mobileService = !this.mobileService;
      }
    },
    moreClick() {
      this.mobileMore = !this.mobileMore;
      this.bodyFixed();
    },
    closeMore() {
      this.mobileMore = false;
      this.bodyFixed();
    },
    bodyFixed() {
      if (this.mobileMore) {
        $(document.body).css("position", "fixed");
      } else {
        $(document.body).removeAttr("style");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 120px;
  background-color: #FFFFFF;
  .header_box {
    width: 100%;
    height: 120px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .logo {
    margin-left: 24px;
    width: 240px;
    height: 120px;
  }
  .nav {
    position: absolute;
    left: 0;
    top: 120px;
    z-index: 1000;
    padding: 32px 60px 65px;
    background: #FFFFFF;
    width: 100%;
    .sort_box {
      li {
        font-size: 28px;
        border-bottom: 1px solid #F5F5F5;
        padding: 16px 0;
      }
    }
    li {
      font-size: 28px;
      border-bottom: 1px solid #F5F5F5;
      padding: 16px 0;
      .user_service {
        display: flex;
        justify-content: space-between;
        align-items: center;
        i {
          font-size: 25px;
          color: #666666;
        }
      }
      .service {
        margin-top: 32px;
        a {
          display: block;
          font-size: 26px;
          color: #666666;
          margin-bottom: 16px;
        }
      }
    }
    .user_box {
      margin-right: 20px;
    }
  }
  .icon_more {
    font-size: 48px;
    margin-right: 60px;
  }
  .mask {
    position: fixed;
    top: 120px;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #000000;
    opacity: 0.55;
    z-index: 10;
  }
}
@media (min-width: 750px) and (max-width: 960px) {
  .header {
    width: 100%;
    height: 120px;
    background-color: #FFFFFF;
    .header_box {
      width: 100%;
      height: 120px;
      background-color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
    .logo {
      margin-left: 24px;
      width: 240px;
      height: 120px;
    }
    .nav {
      position: absolute;
      left: 0;
      top: 120px;
      z-index: 1000;
      padding: 32px 60px 65px;
      background: #FFFFFF;
      width: 100%;
      .sort_box {
        li {
          font-size: 28px;
          border-bottom: 1px solid #F5F5F5;
          padding: 16px 0;
        }
      }
      li {
        font-size: 28px;
        border-bottom: 1px solid #F5F5F5;
        padding: 16px 0;
        .user_service {
          display: flex;
          justify-content: space-between;
          align-items: center;
          i {
            font-size: 25px;
            color: #666666;
          }
        }
        .service {
          margin-top: 32px;
          a {
            display: block;
            font-size: 26px;
            color: #666666;
            margin-bottom: 16px;
          }
        }
      }
      .user_box {
        margin-right: 20px;
      }
    }
    .icon_more {
      font-size: 48px;
      margin-right: 60px;
    }
    .mask {
      position: fixed;
      top: 120px;
      left: 0;
      bottom: 0;
      width: 100%;
      background: #000000;
      opacity: 0.55;
      z-index: 10;
    }
  }
}
@media (min-width: 961px) {
  .header {
    width: 100%;
    height: 72px;
    background-color: #FFFFFF;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .header_box {
      width: 1200px;
      margin: 0 auto;
      height: 72px;
      background-color: #FFFFFF;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
    }
    .logo {
      margin-left: 0;
      width: 144px;
      height: 72px;
    }
    .nav {
      margin-right: 0;
      display: flex !important;
      align-items: center;
      height: 100%;
      position: relative;
      top: 0;
      right: 0;
      z-index: 1000;
      padding: 0;
      background: #FFFFFF;
      width: auto;
      margin-left: 60px;
      .sort_box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        li {
          cursor: pointer;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          margin-left: 40px;
          position: relative;
          min-width: 64px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          &:first-child {
            margin-left: 40px;
          }
        }
      }
      li {
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        margin-left: 40px;
        position: relative;
        min-width: 64px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .user_service {
          i {
            font-size: 12px;
          }
        }
        .service {
          position: absolute;
          left: 0;
          top: 72px;
          z-index: 10;
          margin-top: 0;
          display: none;
          a {
            font-size: 16px;
            color: #666666;
            line-height: 21px;
            margin-top: 20px;
            cursor: pointer;
            margin-bottom: 0;
          }
        }
        &.active,
        &:hover {
          a {
            font-weight: 600;
            // color: #0064eb;
          }
          .service {
            a {
              color: #666;
            }
          }
          .user_service {
            span {
              color: #0064EB;
            }
            i {
              color: #0064EB;
            }
          }
          //i {
          //  color: #0064EB;
          //}
          &:after {
            // transform: scale(1);
            opacity: 1;
          }
          //.service {
          //  display: block !important;
          //}
        }
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 10px;
          height: 15px;
          width: 30px;
          background-image: url('../../assets/images/menu_active.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          // background: #0064eb;
          transition: transform 0.2s ease-out, opacity 0.2s ease-out;
          transform-origin: 50% 0;
          // transform: scaleX(0.01);
          transform: translateX(-50%);
          opacity: 0;
        }
        &:first-child {
          margin-left: 0;
        }
        a {
          font-size: 16px;
          color: #333333;
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
        }
        i {
          font-size: 12px;
          color: #333333;
          position: absolute;
          right: -20px;
          top: 30px;
          z-index: 6;
        }
      }
    }
    .icon_more {
      display: none;
    }
    .mask {
      position: absolute;
      top: 72px;
      left: 0;
      width: 100%;
      height: 102px;
      background: #FFF;
      display: none;
      z-index: 5;
      opacity: 1;
    }
  }
}
</style>
