import sensors from 'sa-sdk-javascript';

sensors.init({
  sdk_url: 'https://djcps.oss-cn-hangzhou.aliyuncs.com/web/static/sensorsdata.full.js',
  heatmap_url: 'https://cdn.jsdelivr.net/npm/sa-sdk-javascript@1.14.15/heatmap.min.js',
  name: 'sensors',
  server_url: process.env.VUE_APP_SHENCE_URL,
  is_track_single_page: true,
  show_log: false,
  send_type: 'ajax',
  heatmap: {
    clickmap: 'default',
    scroll_notice_map: 'default'
  }
})
sensors.quick('autoTrack');

export default sensors
